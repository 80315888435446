<div class="p2-app">
    <div *ngIf="getEvents()?.length == 0 && !isLoading" class="card">
        <h4 class="text-with-icon">
            <i class="fa-regular fa-face-sad-tear" *ngIf="onlyShowUserEvents"></i>No events
        </h4>
        <h5><a routerLink="/events" *ngIf="onlyShowUserEvents">Checkout the events</a></h5>
    </div>

    <div *ngIf="isLoading" class="loading-indicator" [loading]="isLoading"></div>

    <ul *ngIf="!isLoading">
        <li *ngIf="showMentoring">
            <div class="event-card">
                <div class="image">
                    <img class="mobile" src="../../assets/mentoring-1.png" width="600" height="450" priority>
                    <div class="event-type text-with-icon">
                        <div style="background: #cccccc;" class="color-swatch">&nbsp;</div>
                        <span>Mentoring</span>
                    </div>
                </div>
                <div class="details">
                    <h1>Talk to a Mentor Mom</h1>
                    <div class="flex-row tight date-time responsive">
                        Let us introduce you to a Mentor Mom. She is an experienced parent who knows about the challenges you're facing and wants to be there for you every step of the way. Meet for coffee or just text!  For moms 23 and under. 
                    </div>
                </div>
                <footer>
                    <div class="flex-row tight buttons">
                        <button class="light-blue" routerLink="/mentoring">Learn more</button>
                        <button class="secondary" (click)="onHideMentoring()">Hide</button>
                    </div>
                </footer>
            </div>
        </li>
        <li *ngFor="let event of getEvents()" itemscope itemtype="http://schema.org/Event">
            <meta itemprop="startDate" [content]="getIsoDate(event.startTime)" />
            <meta itemprop="endDate" [content]="getIsoDate(event.endTime)" />
            <meta itemprop="organizer" content="Parenting with Purpose" />
            <meta itemprop="url" content="https://equippedparents.org/events/{{ event.guid}}" />
            <meta itemprop="description" [content]="event.description ?? event.name" />
            <meta itemprop="eventAttendanceMode" content="https://schema.org/OfflineEventAttendanceMode" />
            <meta itemprop="eventStatus" content="https://schema.org/EventScheduled" />
            <meta itemprop="address" [content]="getAddress(event.eventLocation)" />
            <meta itemprop="image" [content]="getImageUrl(event, 'full')" />
            <div class="event-card">
                <div class="image">
                    <img [ngSrc]="getImageUrl(event, 'full')" width="600" height="450" priority>
                    <div class="event-type text-with-icon">
                        <div [ngStyle]="{'background': event.eventType.colorHex }" class="color-swatch">&nbsp;</div>
                        <span itemprop="about">{{ event.eventType.name }}</span>
                    </div>
                </div>
                <div class="details">
                    <h1 itemprop="name">{{ event.name }}</h1>
                    <div class="flex-row tight date-time responsive">
                        <span class="text-with-icon">
                            <img src="../../../assets/calendar-icon.png">
                            <span>{{ event.startTime | date: 'MMMM d, yyyy'}}</span>
                        </span>
                        <span class="text-with-icon">
                            <img src="../../../assets/wrist-watch-icon.png">
                            <span>{{ event.startTime | date: 'h:mm aa'}}</span>
                            <span *ngIf="!!event.endTime">- <span>{{ event.endTime | date: 'h:mm aa'}}</span></span>
                        </span>
                    </div>
                    <div>
                        <span class="text-with-icon">
                            <img src="../../../assets/location-pin-icon.png">
                            <span itemprop="location">{{ event.eventLocation.name }}</span>
                        </span>
                    </div>
                    <share-buttons 
                        [include]="['copy','sms','email','x','pinterest','facebook']"
                        [show]="3"
                        [url]="'https://equippedparents.org/events/' + event.guid"
                    ></share-buttons>
                    <span class="text-with-icon show-details" (click)="event.isExpanded = !event.isExpanded">
                        <img [src]="event.isExpanded ? '../../../assets/chevron-up.png' : '../../../assets/chevron-down.png'"> 
                        Details
                    </span>
                    <div class="flex-column tight" *ngIf="event.isExpanded" [@expandCollapse]>
                        <p itemprop="description">{{ event.description }}</p>

                        <div>
                            <div><b>{{ event.eventLocation.name }}</b></div>
                            <div>{{ event.eventLocation.address1 }}</div>
                            <div *ngIf="event.eventLocation.address2?.length > 0">{{ event.eventLocation.address2 }}</div>
                            <div>
                                {{ event.eventLocation.city }}, {{ event.eventLocation.state }} {{ event.eventLocation.postalCode }}
                            </div>
                        </div>
                        <iframe *ngIf="!!getMapAddress(event.eventLocation)"
                            width="450"
                            height="250"
                            frameborder="0" style="border:0"
                            referrerpolicy="no-referrer-when-downgrade"
                            [src]="getMapUrl(event.eventLocation)"
                            allowfullscreen>
                        </iframe>
                        <a itemprop="url" href="https://equippedparents.org/events/{{ event.guid}}" target="_blank">Event page</a>
                    </div>
                </div>
                <footer>
                    <ng-container *ngIf="!isCurrentUserRegistered(event)">
                        @if (isVolunteer()) {
                            @if (event.maxVolunteersReached) {
                                <button class="secondary" disabled>No more volunteers are needed</button>
                            } 
                            @else {
                                <button class="light-blue" (click)="onRegisterClick(event)">Register to Volunteer</button>
                            }
                        }
                        @else {
                            @if (event.maxRegistrantsReached) {
                                <button class="secondary" disabled>Event is Full</button>
                            } 
                            @else if (isDeadlinePassed(event)) {
                                <button class="secondary" disabled>Registration has closed</button>
                            }
                            @else {
                                <button class="light-blue" (click)="onRegisterClick(event)">Register</button>
                            }
                        }
                    </ng-container>
                    
                    <div class="flex-column tight center" *ngIf="isCurrentUserRegistered(event)">
                        <h5 class="text-with-icon"><i class="fa fa-check-circle"></i>Registered</h5>
                    
                        <div class="flex-row tight buttons">
                            <button class="light-blue" (click)="onRegisterClick(event)">Modify</button>
                            <button class="secondary" (click)="onCancelRegistrationClick(event)">Cancel</button>
                        </div>
                    </div>
                </footer>
            </div>
        </li>
    </ul>
</div>