import { Component, Inject, Input, OnInit } from '@angular/core';
import { EventSearchCriteria } from 'src/app/_models/event-search-criteria ';
import { EventService } from 'src/app/_services/event.service';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { map, switchMap, take } from 'rxjs/operators';
import { Event } from 'src/app/_models/event';
import { environment } from 'src/environments/environment';
import { expandCollapse } from '../animations/animations';
import { Dialog } from '@angular/cdk/dialog';
import { EventRegistrationComponent } from '../event-registration/event-registration.component';
import { ContactService } from 'src/app/_services/contact.service';
import { Contact } from 'src/app/_models/contact';
import { ContactEvent } from 'src/app/_models/contact-event';
import { forkJoin, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { EventLocation } from 'src/app/_models/event-location';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
    event: Event;
    contact: Contact;
}

@Component({
    selector: 'event-calendar',
    templateUrl: './event-calendar.component.html',
    styleUrls: ['./event-calendar.component.scss'],
    animations: [
        expandCollapse
    ]
})
export class EventCalendarComponent implements OnInit {
    @Input() onlyShowUserEvents: boolean = false;
    @Input() id: string;  
	@Input() showAllEvents: boolean = false;
	@Input() register: boolean = false;
    @Input() showMentoring: boolean = false;

    criteria: EventSearchCriteria = {} as EventSearchCriteria;
    events: Event[] = [];
    totalCount: number;
    isLoading: boolean = true;

    currentUser: Contact;
    currentUserEvents: ContactEvent[] = [];

    api: string = environment.api;

    constructor(private eventService: EventService, private contactService: ContactService, public dialog: Dialog, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.showMentoring = this.showMentoring && localStorage.getItem('showMentoring') != 'false';

        this.criteria.eventDateTimeStart = dayjs().format('YYYY-MM-DD');

        this.criteria.isPrivate = false;
        this.criteria.pageNumber = 1;
        this.criteria.pageSize = 25;
        this.criteria.sortField = 'EventDateTime';
        this.criteria.sortDirection = 'asc';

        let oneEventCriteria = _.cloneDeep(this.criteria);
        
        if (this.id?.length > 0) {
            oneEventCriteria.eventGuid = this.id;
            oneEventCriteria.eventDateTimeStart = null;
        }

        // If no ID is provided, the other inputs must be overridden
        if (!this.id) {
            this.register = false;
            this.showAllEvents = true;
        }

        forkJoin([
            this.showAllEvents ? this.eventService.searchEvents(this.criteria) : of({ results: [], totalNumResults: 0 }),
            !!this.id ? this.eventService.searchEvents(oneEventCriteria) : of({ results: [], totalNumResults: 0 }),
            !!this.contactService.getCurrentUserGuid() ? this.contactService.getContact(this.contactService.getCurrentUserGuid()) : of(null),
            !!this.contactService.getCurrentUserGuid() ? this.contactService.getEventsForContact(this.contactService.getCurrentUserGuid()) : of([])
        ]).pipe(take(1)).subscribe(([events, specifiedEvent, contact, contactEvents]) => {
            this.currentUser = contact;
            this.currentUserEvents = _.filter(contactEvents, ce => dayjs(ce.event.startTime).isAfter(dayjs(), 'day') || dayjs(ce.event.startTime).isSame(dayjs(), 'day'));

            this.events = events.results;

            if (specifiedEvent.results.length > 0) {
                if (!_.some(this.events, e => e.guid === specifiedEvent.results[0].guid)) {
                    this.events.splice(0, 0, specifiedEvent.results[0]);
                }
            }

            if (this.onlyShowUserEvents) {
                this.totalCount = this.currentUserEvents?.length;
            }
            else {
                this.totalCount = events.totalNumResults;
            }

            this.isLoading = false;

            if (!!this.id && this.register) {
                const selectedEvent = _.find(this.events, e => e.guid === this.id);

                if (!!selectedEvent) {
                    this.onRegisterClick(selectedEvent);
                }
            }
        })
    }

    getEvents() {
        if (this.onlyShowUserEvents) {
            return _.filter(this.events, e => this.isCurrentUserRegistered(e));
        }
        else {
            return this.events;
        }
    }

    refreshUserData(): void {
        let userGuid = this.contactService.getCurrentUserGuid();

        if (!!userGuid) {
            forkJoin([
                this.contactService.getContact(userGuid),
                this.contactService.getEventsForContact(userGuid)
            ]).pipe(take(1)).subscribe(([contact, contactEvents]) => {
                this.currentUser = contact;
                this.currentUserEvents = contactEvents;

                if (this.onlyShowUserEvents) {
                    this.totalCount = this.currentUserEvents?.length;
                }
            });
        }
    }

    getImageUrl(event: Event, imageType: string): string {
        return `${this.api}events/${event.guid}/image/${imageType}`;
    }

    onRegisterClick(event: Event) {
        const dialogRef = this.dialog.open<string>(EventRegistrationComponent, {
            data: { event: event, contact: this.currentUser },
            panelClass: 'full-height-dialog'
        });

        dialogRef.closed.subscribe(result => {
            if (!!result) {
                this.refreshUserData();
            }
        });
    }

    onCancelRegistrationClick(event: Event) {
        const dialogRef = this.dialog.open<boolean>(ConfirmationDialogComponent, {
            data: { 
                title: 'Cancel registration?', 
                message: `Are you sure you want to cancel your registration to ${event.name}?`,
                yesButton: 'Cancel registration',
                noButton: 'Keep registration'
            }
        });

        dialogRef.closed.subscribe(result => {
            if (result == true) {
                this.eventService.cancelEventRegistration(event.guid, this.currentUser.guid).pipe(take(1)).subscribe(res => {
                    this.refreshUserData();
                });
            }
        });
    }

    isCurrentUserRegistered(event: Event) {
        return !!this.currentUserEvents.find(ce => ce.eventGuid === event.guid);
    }

    getMapAddress(eventLocation: EventLocation): string {
        return encodeURIComponent(this.getAddress(eventLocation));
    }

    getMapUrl(eventLocation: EventLocation): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyBjkMHHj2SVm5WV0Ktw_GrcGZd5ibjvh4g&q=${ this.getMapAddress(eventLocation) }`);
    }

    getAddress(eventLocation: EventLocation): string {
        let address: string = '';

        if (!!eventLocation.name) {
            address += eventLocation.name + ',';
        }
        if (!!eventLocation.address1) {
            address += eventLocation.address1 + ',';
        }
        if (!!eventLocation.address2) {
            address += eventLocation.address2 + ',';
        }
        if (!!eventLocation.city) {
            address += eventLocation.city + ',';
        }
        if (!!eventLocation.state) {
            address += eventLocation.state + ' ';
        }
        if (!!eventLocation.postalCode) {
            address += eventLocation.postalCode + ',';
        }
        
        return address;
    }

    getIsoDate(date: Date) {
        return dayjs(date).toISOString();
    }

    isVolunteer() {
        return !!this.currentUser?.roles && this.currentUser.roles.length > 0 && _.some(this.currentUser.roles, r => r.name == 'Volunteer');
    }

    isDeadlinePassed(event: Event) {
        return !!event.registrationDeadline && dayjs(event.registrationDeadline).isBefore(dayjs());
    }

    onHideMentoring() {
        this.showMentoring = false;

        localStorage.setItem('showMentoring', 'false');
    }
}
